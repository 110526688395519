import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './EquilibriumGuide.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { ButtonType1 } from '../../components/Button/ButtonType1';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page1 from '../../assets/images/EquilibriumGuide/59.jpg';
import page2 from '../../assets/images/EquilibriumGuide/70.jpg';
import page3 from '../../assets/images/EquilibriumGuide/79a.jpg';
import page4 from '../../assets/images/EquilibriumGuide/79b.jpg';
import page5 from '../../assets/images/EquilibriumGuide/84a.jpg';
import page6 from '../../assets/images/EquilibriumGuide/84b.jpg';

// 평형안내
const EquilibriumGuide = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isClicked, setIsClicked] = useState("전체");

  return (
    <div>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        
        <div>
          <div className={styles.buttonBoxTitle}>평형 선택</div>
          <div className={styles.buttonBox}>
            <ButtonType1 title="전체" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="59" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="70" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="79A" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="79B" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="84A" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
            <ButtonType1 title="84B" isClick={isClicked} onClicked={(e) => setIsClicked(e)} />
          </div>
        </div>

        <div className={styles.imageBox}>
          <ImageBoxContainer type={isClicked} />
        </div>
      </div>

      <Footer />
      <FixedButton />
      {/* <IpBox /> */}
    </div>
  );
};

export default EquilibriumGuide;


const ImageBoxContainer = ({ type }) => {
  if (type === "59") {
    return (
        <img className={styles.image} src={page1} alt="59" />
    )
  } else if (type === "70") {
    return (
        <img className={styles.image} src={page2} alt="70" />
    )
  } else if (type === "79A") {
    return (
        <img className={styles.image} src={page3} alt="79A" />
    )
  } else if (type === "79B") {
    return (
        <img className={styles.image} src={page4} alt="79B" />
    )
  } else if (type === "84A") {
    return (
        <img className={styles.image} src={page5} alt="84A" />
    )
  } else if (type === "84B") {
    return (
        <img className={styles.image} src={page6} alt="84B" />
    )
  } else {
    return (
      <>
        <img className={styles.image} src={page1} alt="59" />
        <img className={styles.image} src={page2} alt="70" />
        <img className={styles.image} src={page3} alt="79A" />
        <img className={styles.image} src={page4} alt="79B" />
        <img className={styles.image} src={page5} alt="84A" />
        <img className={styles.image} src={page6} alt="84B" />
      </>
    )
  }
}
