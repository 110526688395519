import React from "react";
import { Link } from "react-router-dom";
import styles from './MainMenuBox.module.scss';
import 사업개요 from '../../assets/images/ButtonBox/사업개요.jpg';
import 단지안내 from '../../assets/images/ButtonBox/단지안내.jpg';
import 방문예약 from '../../assets/images/ButtonBox/방문예약.png';
import 상담전화 from '../../assets/images/ButtonBox/상담전화.jpg';
import 세대안내 from '../../assets/images/ButtonBox/세대안내.png';
import 입지환경 from '../../assets/images/ButtonBox/입지환경.jpg';
import 방문예약2 from '../../assets/images/ButtonBox/방문예약2.jpg';
import 세대안내2 from '../../assets/images/ButtonBox/세대안내2.jpg';

export const MainMenuBox = ({ type }) => {
  const phoneNumber = '1666-0995';

  return (
    <>
      {type ? (
        <div className={styles.mobileContainer}>
          <div className={styles.mobileFloor}>
            <Link to='/BusinessGuide'>
              <img src={사업개요} alt="gogleseo-BusinessGuide-image" />
            </Link>
            <Link to='/LocationEnvironment'>
              <img src={입지환경} alt="gogleseo-LocationEnvironment" />
            </Link>
          </div>

          <Link to='/GuideComplex' >
            <img src={단지안내} alt="GuideComplex-image" />
          </Link>

          <div className={styles.mobileFloor}>
            <Link to='/EquilibriumGuide'>
              <img src={세대안내2} alt="GuideComplex-image" />
            </Link>
            <a href='https://www.synapoffice.com/_6dtJ$' target='_blank'>
              <img src={방문예약2} alt="a telephone call for counseling" />
            </a>
          </div>

          <a href={`tel:${phoneNumber}`}>
            <img src={상담전화} alt="a telephone call for counseling" />
          </a>
        </div>
      ) : (
        <div className={styles.webContainer}>

          <div className={styles.floor1}>
            <Link to='/BusinessGuide'>
              <img src={사업개요} alt="gogleseo-BusinessGuide-image" />
            </Link>
            <Link to='/LocationEnvironment'>
              <img src={입지환경} alt="gogleseo-LocationEnvironment-image" />
            </Link>

            <a href={`tel:${phoneNumber}`}>
              <img src={상담전화} alt="a telephone call for counseling" />
            </a>
          </div>

          <div className={styles.floor2}>
            <Link to='/GuideComplex' >
              <img className={styles.floor2Left} src={단지안내} alt="GuideComplex-image" />
            </Link>

            <div className={styles.floor2Right}>
              <Link to='/EquilibriumGuide'>
                <img src={세대안내} alt="GuideComplex-image" />
              </Link>
              <a href='https://www.synapoffice.com/_6dtJ$' target='_blank'>
                <img src={방문예약} alt="Visit reservation-image" />
              </a>
            </div>

          </div>
        </div>
      )
      }
    </>
  )
}
