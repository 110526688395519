import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './GuideComplex.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page1 from "../../assets/images/GuideComplex/page1.jpg";
import page2 from "../../assets/images/GuideComplex/page2.jpg";
import page3 from "../../assets/images/GuideComplex/page3.jpg";
import page4 from "../../assets/images/GuideComplex/page4.jpg";
import page5 from "../../assets/images/GuideComplex/page5.jpg";
import page6 from "../../assets/images/GuideComplex/page6.jpg";
import page7 from "../../assets/images/GuideComplex/page7.jpg";
import page8 from "../../assets/images/GuideComplex/page8.jpg";
import page9 from "../../assets/images/GuideComplex/page9.jpg";
import page10 from "../../assets/images/GuideComplex/page10.jpg";


// 단지안내
const GuideComplex = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        <div className={styles.imageBox}>
          <img className={styles.image} src={page1} alt="gogleseo-GuideComplex1" />
          <img className={styles.image} src={page2} alt="gogleseo-GuideComplex2" />
          <img className={styles.image} src={page3} alt="gogleseo-GuideComplex3" />
          <img className={styles.image} src={page4} alt="gogleseo-GuideComplex4" />
          <img className={styles.image} src={page5} alt="gogleseo-GuideComplex5" />
          <img className={styles.image} src={page6} alt="gogleseo-GuideComplex6" />
          <img className={styles.image} src={page7} alt="gogleseo-GuideComplex7" />
          <img className={styles.image} src={page8} alt="gogleseo-GuideComplex8" />
          <img className={styles.image} src={page9} alt="gogleseo-GuideComplex9" />
          <img className={styles.image} src={page10} alt="gogleseo-GuideComplex10" />
        </div>
      </div>

      <Footer />
      <FixedButton />
      {/* <IpBox /> */}
    </div>
  );
};

export default GuideComplex;
