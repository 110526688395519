import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { IoCloseOutline, IoCallSharp } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";

import styles from './Header.module.scss'
import logo from "../../assets/logo/logoImage.jpg";

export const Header = ({ pathName }) => {
	const isMobile = useMediaQuery({ query: '(max-width: 980px)' });
	const isRedBox = useMediaQuery({ query: '(max-width: 1120px)' });
	const [isHeaderVisible, setIsHeaderVisible] = useState(false); // 모바일에서 헤더 메뉴를 보여줄지 여부
	const [path, setPath] = useState(pathName);
	const phoneNumber = '1666-0995';

	const handleNavigate = pathName => {
		setIsHeaderVisible(false);
		if (pathName === '') window.location.href = '/';
		else window.location.href = pathName;
	};

	return (
		<>
			{isMobile ? (
				<header className={styles.header}>
					<Link to='/'>
						<img className={styles.logo} src={logo} alt="Hyundai Engineering & Construction Hillstate" />
					</Link>

					<div className={styles.iconBox}>
						<a href={`tel:${phoneNumber}`}>
							<IoCallSharp className={styles.callIcon} color="#7a0b01" />
						</a>
						<AiOutlineMenu className={styles.menuIcon} color="#7a0b01" onClick={() => setIsHeaderVisible(!isHeaderVisible)} />
					</div>

					{isHeaderVisible && (
						<div className={styles.overlay}>
							<IoCloseOutline
								onClick={() => setIsHeaderVisible(false)}
								className={styles.closeButton}
								color="#000000"
								size={25}
							/>
							<ul className={styles.MobileMenu}>
								<Link className={styles.item} to='/' >
									<li>HOME</li>
								</Link>
								<Link className={styles.item} to='/BusinessGuide'>
									<li>사업안내</li>
								</Link>
								<Link className={styles.item} to='/LocationEnvironment'>
									<li>입지환경</li>
								</Link>
								<Link className={styles.item} to='/Premium'>
									<li>프리미엄</li>
								</Link>
								<Link className={styles.item} to='/GuideComplex'>
									<li>단지안내</li>
								</Link>
								<Link className={styles.item} to='/EquilibriumGuide'>
									<li>평형안내</li>
								</Link>
							</ul>
						</div>
					)}
				</header>
			) : (
				<header className={styles.header}>

					<Link className={styles.text1} to='/'>
						<img className={styles.logo} src={logo} alt="Hyundai Engineering & Construction Hillstate" />
					</Link>

					{/* <div className={styles.addContainer}>
						<Link className={styles.text1} to='/'>
							<div className={styles.text11}>힐스테이트 가장 더퍼스트</div>
							<div className={styles.text12}>가장동 힐스테이트 더퍼스트</div>
						</Link>
						<a className={styles.text3} href="https://www.hillstate-thefrist.com">공식 홈페이지</a>
					</div> */}

					<div className={styles.menu} >
						<Link to='/BusinessGuide' className={`${styles.unHiperLinkStyle} ${[path === '/BusinessGuide' ? styles.boldLink : styles.link]}`}>
							<div>사업안내</div>
						</Link>
						<Link to='/LocationEnvironment' className={`${styles.unHiperLinkStyle} ${[path === '/LocationEnvironment' ? styles.boldLink : styles.link]}`}>
							<div>입지환경</div>
						</Link>
						<Link to='/Premium' className={`${styles.unHiperLinkStyle} ${[path === '/Premium' ? styles.boldLink : styles.link]}`}>
							<div>프리미엄</div>
						</Link>
						<Link to='/GuideComplex' className={`${styles.unHiperLinkStyle} ${[path === '/GuideComplex' ? styles.boldLink : styles.link]}`}>
							<div>단지안내</div>
						</Link>
						<Link to='/EquilibriumGuide' className={`${styles.unHiperLinkStyle} ${[path === '/EquilibriumGuide' ? styles.boldLink : styles.link]}`}>
							<div>평형안내</div>
						</Link>

						<a className={styles.unHiperLinkStyle} href='https://www.synapoffice.com/_6dtJ$' target='_blank'>
							<IoCallSharp size={13} /> 상담예약
						</a>
					</div>
				</header >
			)}
		</>

	)
}
