import React from 'react';
import { useLocation } from 'react-router-dom';
// import { useMediaQuery } from 'react-responsive';

import styles from './BusinessGuide.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page1 from '../../assets/images/BusinessGuide/page1.jpg';
import page2 from '../../assets/images/BusinessGuide/page2.jpg';
import page3 from '../../assets/images/BusinessGuide/page3.jpg';
import page4 from '../../assets/images/BusinessGuide/page4.jpg';
import page5 from '../../assets/images/BusinessGuide/page5.jpg';


// 사업안내
const BusinessGuide = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        <div className={styles.imageBox}>
          <img className={styles.image} src={page1} alt="gogleseo-BusinessGuide1" />
          <img className={styles.image} src={page2} alt="gogleseo-BusinessGuide2" />
          <img className={styles.image} src={page3} alt="gogleseo-BusinessGuide3" />
          <img className={styles.image} src={page4} alt="gogleseo-BusinessGuide4" />
          <img className={styles.image} src={page5} alt="gogleseo-BusinessGuide5" />
        </div>

        <Footer />
        <FixedButton />
        {/* <IpBox /> */}
      </div>
    </>
  );
};

export default BusinessGuide;
