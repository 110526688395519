import React from 'react';
import { useLocation } from 'react-router-dom';

import styles from './Premium.module.scss';
import { Header } from '../../components/Header/Header';
import { Footer } from '../../components/Footer/Footer';
import { FixedButton } from '../../components/Button/FixedButton';
import { IpBox } from '../../components/IpBox/IpBox';
import page1 from '../../assets/images/Premium/page1.png';
import page2 from '../../assets/images/Premium/page2.png';
import page3 from '../../assets/images/Premium/page3.png';

// 프리미엄
const Premium = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div>
      <Header pathName={currentPath} />

      <div className={styles.container}>
        <div className={styles.imageBox}>
          <img className={styles.image} src={page1} alt="Premium1" />
          <img className={styles.image} src={page2} alt="Premium2" />
          <img className={styles.image} src={page3} alt="Premium3" />
        </div>
      </div>

      <Footer />
      <FixedButton />
      {/* <IpBox /> */}
    </div>
  );
};

export default Premium;
